.headerContainer {
    width: 100%;
    height: 65px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-inline: 0 !important;
    background: #fff !important;
    font-size: 13px;
    //box-shadow: 0 -2px 14px #828282;
    border-bottom: 1px solid #e5e5e5;
    z-index: 10;

    > div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        margin: 0 30px;
    }
}

.userInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    > span {
        margin-left: 10px;
    }
}
